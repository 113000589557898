<template>
  <v-card class="mx-auto">
    <v-card-title>
      <span class="font-weight-light text-capitalize text-truncate">
        <v-icon left>{{ icon }}</v-icon
        >{{ item.firstName }} {{ item.lastName }}</span
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle class="px-4 py-2" v-if="item.username"
      ><v-icon left>mdi-account-circle</v-icon>
      {{ item.username }}
    </v-card-subtitle>
    <v-card-subtitle class="px-4 py-2" v-if="item.email"
      ><v-icon left>mdi-email-outline</v-icon>
      {{ item.email }}
    </v-card-subtitle>
    <v-card-subtitle class="px-4 py-2" v-if="item.cityName"
      ><v-icon left>mdi-city</v-icon>
      {{ item.cityName }}
    </v-card-subtitle>
    <v-card-subtitle class="px-4 py-2" v-if="item.salesCompany"
      ><v-icon left>mdi-briefcase</v-icon>
      {{ item.salesCompany }}
    </v-card-subtitle>
    <v-card-subtitle class="px-4 py-2" v-if="item.shop"
      ><v-icon left>mdi-briefcase-variant</v-icon>
      {{ item.shop }}
    </v-card-subtitle>
    <v-divider v-if="showActions"> </v-divider>
    <v-card-actions v-if="showActions">
      <v-tooltip bottom v-if="item.state == 'Active'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            plain
            color="error"
            @click="deactivateAccount(item.id)"
          >
            <v-icon left>mdi-alert-octagon</v-icon>
            {{ $t("app.deactivate") }}
          </v-btn>
        </template>
        <span>{{ $t("tooltip.deactivate") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.state == 'InActive'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            color="success"
            @click="activateAccount(item.id)"
          >
            <v-icon left>mdi-check-all</v-icon>
            {{ $t("app.activate") }}
          </v-btn>
        </template>
        <span>{{ $t("tooltip.activate") }}</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="item.state == 'Created'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            plain
            color="info"
            @click="sendConfirmation(item.email)"
          >
            <v-icon left>mdi-email</v-icon>
            {{ $t("app.sendConfirmation") }}
          </v-btn>
        </template>
        <span>{{ $t("tooltip.sendConfirmation") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.state == 'WaitingApproval'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            color="success"
            @click="approveAccount(item.id)"
          >
            <v-icon left>mdi-account-check</v-icon>
            {{ $t("app.approve") }}
          </v-btn>
        </template>
        <span>{{ $t("tooltip.approve") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :to="{
              name: 'show-' + [namespace],
              params: { id: item.id }
            }"
            class="pa-1 mx-2"
            icon
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.show") }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      confirmDeleteDialog: false
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    limitText: {
      type: String,
      required: false,
      default: "40"
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    namespace: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    async activateAccount(id) {
      await this.$store.dispatch(`${this.namespace}/activate`, id);
      Event.$emit("reload-data");
    },
    async approveAccount(id) {
      await this.$store.dispatch(`${this.namespace}/approve`, id);
      Event.$emit("reload-data");
    },
    async deactivateAccount(id) {
      await this.$store.dispatch(`${this.namespace}/deactivate`, id);
      Event.$emit("reload-data");
    },
    sendConfirmation(email) {
      this.$store.dispatch(`${this.namespace}/sendConfirmation`, email);
    }
  }
};
</script>

<style scoped>
.break-all-words >>> p {
  margin-bottom: 0 !important;
}
.break-all-words {
  word-break: break-all;
}
</style>
